import LookupCheckboxItem from "../Types/LookupCheckboxItem";
import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { notification } from "antd";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const useFetchLookupCheckbox = (setLookupCheckboxData: React.Dispatch<React.SetStateAction<LookupCheckboxItem[]>>) => {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();
    const navigate = useNavigate();


    const fetchLookupCheckbox = async () => {
        if(error){ return new Array<LookupCheckboxItem>(); }
        try {
            const { data } = await axios.get<LookupCheckboxItem[]>('lookup_checkboxes');
            if(data.length === 0){
                notification.warning({ message: 'Missing lookup checkbox data! Please contact the administrator for assistance.', placement: 'topRight', duration: 0 });
                navigate('/204');
            }
            return data;
        } catch (error) {
            setError(error);
            notification.error({ message: 'Error fetching lookup checkbox!', placement: 'topRight' });
            setError(null);
            return new Array<LookupCheckboxItem>();
        }
    };
    const lookupCheckboxQuery = useQuery<LookupCheckboxItem[], Error>('lookupCheckbox', fetchLookupCheckbox, {
        staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
        cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
        retry: 1,
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(!lookupCheckboxQuery.isLoading){
            if(lookupCheckboxQuery.data){
                setLookupCheckboxData(lookupCheckboxQuery.data);
            }
        }
    }, [lookupCheckboxQuery.data, lookupCheckboxQuery.isLoading, setLookupCheckboxData]);

    return {isLoading: lookupCheckboxQuery.isLoading};
}