import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  {Button, Dropdown, MenuProps, Modal, notification}  from "antd";
import { faArrowRotateRight, faCaretDown, faCircleUser, faSave } from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as Logo} from "../../Assets/Images/Logodark.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormSubmitContext } from "../../Context/FormSubmitContext";
import { useStudentPlan } from "../../Context/StudentPlanContext";
import { ReportMenu } from "./Reports/ReportMenu";
import {ReactComponent as FaSpinnerThird} from "../../Assets/Images/FaSpinnerThird.svg";
import { StudentPlayer } from "./StudentPlayer/StudentPlayer";
import { useAuthAxios } from "../../Hooks/useAuthAxios";
import { useErrorHandling } from "../../Provider/ErrorHandlingProvider";
import AccessLevelType from "../../Enums/AccessLevel";
import { useApp } from "../../Context/AppContext";


interface NavBarProps {
  isShowReport?: boolean;
}

export const NavBar:React.FC<NavBarProps> = ({isShowReport}) => {
    const { logout, user } = useAuth0();
    const navigate = useNavigate();
    const {student} = useStudentPlan();
    const location = useLocation();
    const {formData, initialFormData, handleSubmit, isSubmitting} = useFormSubmitContext();
    const [isFormDataChanged, setIsFormDataChanged] = useState<boolean>(false);
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();
    const {multiMode, setStudentPublicId, accessLevel, multiStudentList} = useApp();



    const logoutWithRedirect = () =>
      logout({
          logoutParams: {
            returnTo: window.location.origin,
          }
      });

    const onPPSDHelpClick = () => {
      window.open("https://csnsw.link/disability-support-kb", "_blank");
    };

    const onPPSDTrainingClick = () => {
        window.open("https://csnsw.link/PPSD-online-course-material", "_blank");
    }

    const onRefreshClick = async () => {
      Modal.confirm({
        title: 'Are you sure you want to clear cache?',
        content: 'Any unsaved changes will be lost and the latest plan details retrieved from the server.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk() { localStorage.clear();
          const endpoint = `Utils/ClearUserCache`;
          try{
            await axios.get<any>(endpoint);
            setError(null);
            window.location.reload();
          }catch{
            setError(error);
            notification.error({ message: 'Error while clearing cache!', placement: 'topRight' });
          }
        },
        onCancel() {  },
      }); 
    };

    const handleLogoClick = () => {
        const currentPath = location.pathname;
        const newPath = currentPath.split('/').slice(0, -1).join('/');
        navigate(newPath, { replace: true });
    };

    useEffect(() => {
      setIsFormDataChanged(JSON.stringify(formData) !== JSON.stringify(initialFormData));
    }, [formData, initialFormData]);


    useEffect(() => {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (isFormDataChanged) {
          event.preventDefault();
          event.returnValue = 'You have unsaved changes, continue modifying plan?';
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [isFormDataChanged]);


    const userMenu : MenuProps['items'] = [
      {key: "username", label: <div className="flex flex-col cursor-default">Hi, {user?.name}!</div> },
      {key: "logout", label: <button className="nav-link text-blue-500" aria-current="page" onClick={logoutWithRedirect}>Sign Out</button> },
      {key: "clear-cache", label: <div onClick={onRefreshClick}>Clear Cache <FontAwesomeIcon icon={faArrowRotateRight} className="mx-1" /></div> }
    ];
  
    const helpMenu: MenuProps['items'] = [
      {key: 1, label: <h1 className="px-2 text-md rounded-sm cursor-pointer hover:bg-blue-300" onClick={onPPSDTrainingClick}>PPSD Training Material</h1>},
      {key: 2, label: <h1 className="px-2 text-md rounded-sm cursor-pointer hover:bg-blue-300" onClick={onPPSDHelpClick}>PPSD/NCCD Information</h1>} 
    ];
  
    return (
        <div className="px-2  flex justify-center w-full bg-white border-b-2 border-blue-400 sticky top-0 z-50">
            <div className="flex w-full items-center max-w-screen-4xl">
                  <div onClick={handleLogoClick} className="nav-link mx-4 cursor-pointer" aria-current="page" >
                    <Logo/>
                  </div>
                  <div className="flex flex-col items-center w-full mr-4">

                      <div className="ml-auto text-sm flex mt-2 items-center">
                          {accessLevel !== AccessLevelType.Full && <div className="mx-2 px-2 shadow-xl bg-orange-300 rounded-md hover:scale-105">Read Only Mode</div>}
                          <Dropdown menu={{items: userMenu}} trigger={['click']}  >
                              <FontAwesomeIcon icon={faCircleUser} className="text-3xl rounded-2xl border-2 shadow-circle hover:shadow-blue-400 border-blue-500" />
                          </Dropdown>
                      </div>
                      
                      <div className="flex items-center border-b-2">
                          <span className="text-lg lg:text-xl font-bold max-w-96 xl:max-w-[700px] truncate">{student.studentFirstName} {student.studentLastName}</span>
                          <span className="text-sm lg:text-md font-bold lg:mx-4">{student.orgnisationName && `| ${student.orgnisationName} | ${student.gradeDescription} |`}</span>
                          <span className="text-sm lg:text-md font-bold mx-1">{student.locationSuburb}</span>
                      </div>

                      <div className="my-2 ml-auto flex justify-center">
                          {multiMode && <StudentPlayer key={`multi-${multiMode}`} multiStudentList={multiStudentList} setStudentPublicId={setStudentPublicId}/>}
                          <Button onClick={handleSubmit} disabled={isSubmitting || !isFormDataChanged || accessLevel !== AccessLevelType.Full} className="mx-2 flex disabled:bg-green-950 bg-green-500 hover:bg-green-600 text-white py-1 rounded">
                            Save{isSubmitting ? <FaSpinnerThird className="mx-2 w-4 animate-spin mt-1 fill-white "/> : <FontAwesomeIcon icon={faSave} className="mx-2 mt-1"/>}
                          </Button>
                          {isShowReport && <ReportMenu />}
                          <Dropdown menu={{items: helpMenu}} trigger={['click']}>
                            <Button className="bg-maroon-500 hover:bg-maroon-600 text-white py-1 px-2 lg:px-4 rounded mx-2">
                                Help<FontAwesomeIcon icon={faCaretDown} className="mx-1 text-sm sm:text-lg"/>
                            </Button>
                          </Dropdown>
                      </div>
                  </div>
            </div>
        </div>
)};

