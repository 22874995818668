import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Watermark, Grid } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import Aboriginal from '../Pages/Aboriginal/Aboriginal';
import { useAuth0 } from '@auth0/auth0-react';
import { FloatingSave } from '../Components/Layout/FloatingSave';
import { NavBar } from '../Components/Layout/NavBar';
import { NavBarMobile } from '../Components/Layout/NavBarMobile';
import { Loading } from '../Components/Layout/Loading';
import { Footer } from '../Components/Layout/Footer';
import { LookupListProvider } from '../Provider/LookupListProvider';
import { LookupCheckboxProvider } from '../Provider/LookupCheckboxProvider';
import { FormSubmitProvider } from '../Provider/FormSubmitProvider';
import { StudentPlanProvider } from '../Provider/StudentPlanProvider';
import StudentNotFound from '../Pages/Common/StudentNotFound';
import { isValidUUID, emptyGuid } from '../Validations/ValidateGuid';
import { ErrorHandlingProvider } from '../Provider/ErrorHandlingProvider';
import InternalServer from '../Pages/Common/InternalServer';
import Unauthorized from '../Pages/Common/Unauthorized';
import { RefsProvider } from '../Provider/RefsProvider';
import { NaplanProvider } from '../Provider/NaplanProvider';
import { NccdDisabilityProvider } from '../Provider/NccdDisabilityProvider';
import { useAuthAxios } from '../Hooks/useAuthAxios';
import { SelectedStudent } from '../Class/SelectedStudent';
import PlanType from '../Enums/PlanType';
import { useApp } from '../Context/AppContext';
import { ContentNotFound } from '../Pages/Common/ContentNotFound';
const Disability = lazy(() => import('../Pages/Disablity/Disability'));
const NotFound = lazy(() => import('../Pages/Common/NotFound'));


const App = ()=> {
    const { user, isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const queryClient = new QueryClient();
    const navigate = useNavigate();
    const {axios} = useAuthAxios();
    const {studentPublicId, setStudentPublicId, setMultiMode, setPlanType, setMultiStudentList} = useApp();
    

    useEffect(() => {
        const fn = async () => {
          if (!isLoading && !isAuthenticated) {
            const currentPath = window.location.pathname + window.location.search;
            const urlSegments = currentPath.split('/');
            const potentialUUID = urlSegments[1];
            if (isValidUUID(potentialUUID)) {
              localStorage.setItem('urlBeforeLogin', currentPath);
            }
            try {
              await getAccessTokenSilently();
            } catch (e) {
              loginWithRedirect();
            }
          }
        };
        fn();
    }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently]);


    useEffect(() => {
      const dataFetch = async () => {
        if(!isAuthenticated || isLoading || !user?.nickname){ return; }

        var urlFromStorage = localStorage.getItem('urlBeforeLogin');
        var url = urlFromStorage ? urlFromStorage : window.location.pathname;
        var segments = url.split('/');
        const potentialUUID = segments[1];

        if(!isValidUUID(potentialUUID)){ navigate('/student-not-found'); return; }
        var newStudentPublicId = potentialUUID;

        if(potentialUUID === emptyGuid){
          try{
            var response = await axios.get<SelectedStudent[]>(`student/GetSelectedStudents/${user?.nickname?.toLowerCase()}`);
            const data = response.data;
  
              if(data && data.length === 0){
                navigate('/student-not-found');
              }
              setMultiStudentList(data.sort((a, b) => a.firstName.localeCompare(b.firstName)));
              setMultiMode(potentialUUID === emptyGuid);

              var prevSelectedStudentPublicId = localStorage.getItem('multiCurrentStudent');
              var index = data.findIndex(s => s.publicId === prevSelectedStudentPublicId);
              newStudentPublicId = index !== -1 ? data[0].publicId : data[0].publicId;
          } catch(e){
              navigate('/student-not-found');
              return;
            }
        }
        setStudentPublicId(newStudentPublicId);

        const newPlanType = segments.find(segment => segment === PlanType.DISABILITY || segment === PlanType.ABORIGINAL) as PlanType || PlanType.DISABILITY;
        setPlanType(newPlanType);
        
        localStorage.removeItem('urlBeforeLogin');
        if(potentialUUID !== emptyGuid){
          const updatedUrl = `${segments[0]}/${newStudentPublicId}/${segments.slice(2).join('/')}`;
          navigate(updatedUrl);
        }
      };
      
      dataFetch();
    }, [isAuthenticated, isLoading]);

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading || !isAuthenticated) {
        return <div><Loading /></div>;
    }


    return (
      <div className='flex flex-col min-h-screen bg-gray-100'>
          <div className='flex flex-col flex-grow'>
            <QueryClientProvider client={queryClient}>
              <ErrorHandlingProvider>
                <LookupListProvider>
                  <LookupCheckboxProvider>
                      {studentPublicId && <StudentPlanProvider>
                        <NccdDisabilityProvider>
                          <NaplanProvider>
                            <FormSubmitProvider>
                              <RefsProvider>
                                {screens.md ? ( <NavBar isShowReport={true}/> ) : ( <NavBarMobile /> )}
                                  <Suspense fallback={<Loading />}>
                                    {/* <Watermark content={process.env.REACT_APP_WATERMARK}> */}
                                        <Routes>
                                          <Route path="/:studentPublicId/" element={<Navigate to={`/${studentPublicId}/disability/consultation-and-collaboration`} replace />} />
                                          <Route path="/:studentPublicId/disability" element={<Disability/>} />
                                          <Route path="/:studentPublicId/disability/consultation-and-collaboration" element={<Disability/>} />
                                          <Route path="/:studentPublicId/disability/assessed-needs" element={<Disability />} />
                                          <Route path="/:studentPublicId/disability/adjustments" element={<Disability/>} />
                                          <Route path="/:studentPublicId/disability/monitoring-and-review" element={<Disability/>} />
                                          <Route path="/:studentPublicId/aboriginal" element={<Aboriginal />} />
                                          <Route path="/student-not-found" element={<StudentNotFound />}></Route>
                                          <Route path="/unauthorized" element={<Unauthorized />}></Route>
                                          <Route path="/internal-server-error" element={<InternalServer />}></Route>
                                          <Route path="/404" element={<NotFound />}></Route>
                                          <Route path="/204" element={<ContentNotFound />}></Route>
                                          <Route path="*" element={<NotFound />}></Route>
                                        </Routes>
                                        {screens.md || screens.sm || <FloatingSave />}
                                  {/* </Watermark> */}
                              </Suspense>
                              <Footer />
                            </RefsProvider>
                          </FormSubmitProvider>
                        </NaplanProvider>
                      </NccdDisabilityProvider>
                  </StudentPlanProvider>}
                  {!studentPublicId && <div>
                    {screens.md ? ( <NavBar /> ) : ( <NavBarMobile /> )}
                    <Routes>
                        <Route path="/student-not-found" element={<StudentNotFound />}></Route>
                    </Routes>
                  </div>}
                </LookupCheckboxProvider>
              </LookupListProvider>
            </ErrorHandlingProvider>
          </QueryClientProvider>
        </div>
      </div>
    );
}

export default App;